:root {
  --bg-primary: #f6f6f6;
  --bg-secondary: #131313;
  --bg-light: #ffffff;
  --bg-grey: #e4e0e1;
  --text-primary: #131313;
  --text-secondary: #ffffff;
  --text-grey: #595959;
  --text-dark-grey: #131313a6;
  --text-light-grey: #f6f6f6cc;
  --success: #28a745;
  --warning: #ffc107;
  --error: #f95454;
  --border: #dddddd;
  --light-shadow: #939185;
  --accent: #2a6231;
  --light-accent: rgb(0, 255, 157);
  --dark-accent: #578884;
}

body {
  font-family: "Poppins", sans-serif, -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  text-decoration: none !important;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  border: 0;
  outline: 0;
}

html,
body {
  height: 100%;
  width: 100%;
  /* margin: 0; */
  min-width: 320px;
  min-height: 100vh;
  background: var(--bg-primary);
  color: var(--text-primary);
  scrollbar-width: thin;
  scrollbar-color: var(--bg-secondary) transparent;
  scroll-behavior: smooth;
  max-width: 1800px;
  margin: auto;
}


/* img {
  max-width: 100%;
} */

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}
