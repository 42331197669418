.outer-main {
  height: 100%;
  width: 100%;
  max-height: 750px;

  .carousel {
    height: 80%;

    .carousel-indicators {
      z-index: 10;
    }

    .carousel-inner {
      height: 100%;
      width: 100%;
      .carousel-item {
        height: 90vh;
        position: relative;

        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }

        .overlay {
          position: absolute;
          inset: 0;
          background: #131313a9;
          background: #13131362;
        }

        .details {
          width: 50%;
          background: #131313a9;

          position: absolute;
          inset: 0;
          z-index: 9;
          display: flex;
          flex-direction: column;
          // justify-content: center;
          // align-items: center;
          padding: 8rem 0 0 4rem;
          // border: 1px solid red;

          .question {
            color: var(--text-secondary);
            width: 90%;
            text-transform: uppercase;
            font-size: 2.2rem;
            font-size: 2rem;
            line-height: 2.5rem;
            // line-height: 0.5rem;
            font-weight: 600;
          }

          .answer {
            width: 70%;
            color: var(--text-secondary);
            font-weight: 100;
            margin-top: 2rem;
            margin-bottom: 2rem;
            text-align: left;
            font-size: 1rem;
          }

          .read-more-container {
            margin-top: 5px;
            display: flex;
            cursor: pointer;
            background: var(--bg-primary);
            width: fit-content;
            padding: 10px 2rem;
            border-radius: 2rem;
            transition: all 0.2s linear;
            z-index: 10;

            .read-more-text {
              font-size: 0.85rem;
              color: var(--text-primary);
              line-height: 1.2rem;
              font-weight: 500;
              transition: all 0.2s linear;
            }

            &:hover {
              background: var(--bg-secondary);

              .read-more-text {
                color: var(--text-secondary);
              }
            }
          }
        }
      }
    }

    .carousel-control-prev,
    .carousel-control-next {
      z-index: 9;
    }
  }
}

@media (max-width: 1130px) {
  .outer-main {
    .carousel {
      .carousel-inner {
        .carousel-item {
          .details {
            width: 50%;
            // justify-content: center;
            // align-items: center;
            padding: 8rem 0 0 4rem;
            // border: 1px solid red;

            .question {
              width: 90%;
              font-size: 2rem;
              line-height: 2.5rem;
              font-weight: 600;
            }

            .answer {
              width: 75%;
              margin: 1.3rem 0;
            }

            .read-more-container {
              margin-top: 5px;
              padding: 10px 2rem;
              .read-more-text {
                font-size: 0.85rem;
                line-height: 1.2rem;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 990px) {
  .outer-main {
    .carousel {
      .carousel-inner {
        .carousel-item {
          .details {
            padding: 5rem 0 0 2rem;
          }
        }
      }
    }
  }
}

@media (max-width: 780px) {
  .outer-main {
    .carousel {
      .carousel-inner {
        .carousel-item {
          .details {
            padding: 3rem 0 0 2rem;
            .question {
              width: 90%;
              font-size: 1.8rem;
              line-height: 2.2rem;
              font-weight: 600;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .outer-main {
    width: 100%;
    height: 80vh;
    margin: 0;
    padding: 0;
    .carousel {
      .carousel-inner {
        .carousel-item {
          height: 100%;
          width: 100%;

          .details {
            width: 100%;
            padding: 3rem 5rem;

            .question {
              width: 100%;
              font-size: 1.8rem;
              line-height: 2rem;
            }

            .answer {
              width: 100%;
              font-size: 1.2rem;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 570px) {
  .outer-main {
    .carousel {
      .carousel-inner {
        .carousel-item {
          .details {
            width: 100%;
            padding: 2rem 2rem;

            .question {
              width: 100%;
              font-size: 1.8rem;
              line-height: 2rem;
            }

            .answer {
              width: 100%;
              font-size: 1.2rem;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 480px) {
  .outer-main {
    width: 100%;

    .carousel {
      .carousel-indicators {
        margin-bottom: 5px;
      }

      .carousel-inner {
        .carousel-item {
          height: 100%;
          width: 100%;

          .details {
            padding: 3rem 3rem;

            .question {
              font-size: 1.3rem;
              line-height: 1.5rem;
            }

            .answer {
              font-size: 1rem;
              margin-top: 1rem;
              margin-bottom: 1rem;
            }
          }
        }
      }
    }
  }
}
